import {
  CONNECTED_BUSINESS_CARDS_MPV_ID,
  DOMAINS_MPV_ID,
  LISTINGS_MANAGER_MPV_ID,
  WEBSITES_MPV_ID,
  WIX_WEBSITES_MPV_ID,
  DIWY_MPV_ID,
} from '@vp/digital-product-availability-lib';

export interface VPTracking {
  visit: () => void;
  page: (pageName: string, properties: object, options?: object) => void;
  track: (eventName: string, properties: object, options?: object) => void;
  trackLink: (
    element: HTMLElement,
    eventName: string,
    properties: object
  ) => void;
  trackNavigation: (
    element: HTMLElement,
    eventName: string,
    properties: object
  ) => void;
  identify: (userIdOrTraits: string | object, traits?: object) => void;
  reset: () => void;
  setAnonymousId: (anonymousId: string) => void;
}

// Various enums for page stage, section, etc defined here:
// https://vistaprint.atlassian.net/wiki/spaces/GA/pages/53903414/Page+name+nomenclature+and+categorization

export enum TrackingPageStage {
  CHECKOUT = 'Checkout',
  CONFIGURE = 'Configure',
  CUSTOMER_CARE = 'Customer Care',
  DESIGN = 'Design',
  DISCOVER = 'Discover',
  MY_ACCOUNT = 'My Account',
  ERROR_PAGE = 'Error Page',
  OTHER = 'Other',
}

export enum TrackingPageSection {
  CART = 'Cart',
  CATEGORY_PAGE = 'Category Page',
  CONFIRMATION = 'Confirmation',
  CONFIGURE_OPTION = 'Configure - Option',
  DIGITAL_GALLERY = 'Digital Gallery',
  DIGITAL_MARKETING = 'Digital Marketing',
  GALLERY = 'Gallery',
  LEGAL = 'Legal',
  LOGIN = 'Login',
  MANAGE = 'Manage',
  STUDIO = 'Studio',
  STUDIO_BUILDER = 'Studio:WebsiteBuilder',
  SEARCH_RESULTS_PAGE = 'Search Results Page',
  PRODUCT_PAGE = 'Product Page',
  PRODUCT_SETUP = 'Product Setup',
  INTERNAL_ERROR = 'Internal Error',
  SEARCH_RESULTS = 'Search Results Page',
  CONFIGURE_OPTIONS = 'Configure - Options',
  LANDING_PAGE = 'Landing Page',
  DIGITAL_CATEGORY = 'Digital Category',
  OTHERS = 'Others',
  FLY_OUT = 'Fly-Out',
  POP_UP = 'Pop-Up',
}

export enum TrackingPageSectionID {
  WIX_WEBSITES_PLANS = 'Websites Plans Tab',
  WIX_BUSINESS_PLANS = 'Business Plans Tab',
}

export enum TrackingAction {
  DOMAIN_SEARCHED = 'Domain Searched',
  DOMAIN_SELECTED = 'Domain Selected',
  POP_UP_VIEWED = 'Pop-Up Viewed',
  POP_UP_CLICKED = 'Pop-Up Clicked',
  POP_UP_CLOSED = 'Pop-Up Closed',
  PRODUCT_ADDED = 'Product Added',
  PRODUCT_VIEWED = 'Product Viewed',
  EXPERIMENT_VIEWED = 'Experiment Viewed',
  EXPERIMENT_CLICKED = 'Experiment Clicked',
  PRODUCT_LIST_VIEWED = 'Product List Viewed',
  PRODUCT_PAGE_VIEW = 'Product Page View',
  FLYOUT_VIEWED = 'Fly-Out Viewed',
  FLYOUT_CLICKED = 'Fly-Out Clicked',
  FLYOUT_CLOSED = 'Fly-Out Closed',
  POPUP_VIEWED = 'Pop-Up Viewed',
  POPUP_CLICKED = 'Pop-Up Clicked',
  POPUP_CLOSED = 'Pop-Up Closed',
  BILLING_FREQUENTLY_TOGGLED = 'Billing Frequency Toggled',
  PLAN_CHOSEN = 'Plan Chosen',
  PLAN_FREQUENCY_UPGRADED = 'Plan Frequency Upgraded',
}

export enum TrackingActionLabel {
  PRODUCT_VIEWED = 'Product Page View',
  EXPERIMENT_VIEWED = 'Experiment Page View',
  EXPERIMENT_CLICKED = 'Experiment Page Click',
}

export enum TrackingPageLocation {
  PRODUCT_CTA_BOTTOM = 'Bottom Marquee',
  PRODUCT_CTA_TOP = 'Top Marquee',
}

export enum TrackingCategory {
  DIGITAL_MARKETING = 'Digital Marketing',
  NAVIGATION = 'Navigation',
  STUDIO_WEBSITE_BUILDER = 'Studio:WebsiteBuilder',
  LOGIN = 'Login',
}
export enum TrackingListIds {
  ANONYMOUS_FLOW = 'Anonymous Flow',
  INITIAL_UPGRADE_FLOW = 'Initial Upgrade  Flow',
  CHANGE_PLAN_FLOW = 'Plan Change Flow',
  SECOND_UPGRADE_FLOW = 'Second Upgrade Flow',
}

export enum TrackingPageDept {
  DIGITAL_MARKETING_WIX = 'Digital Marketing - Wix',
  DIGITAL_MARKETING_WEBSITE = 'Digital Marketing - Website',
  DIGITAL_MARKETING_SELM = 'Digital Marketing - SELM',
  DIGITAL_MARKETING_DOMAIN = 'Digital Marketing - Standalone Domain',
  DIGITAL_MARKETING_PRO_ADVANTAGE = 'Digital Marketing - Pro Advantage',
  DIGITAL_MARKETING_EMAIL_MARKETING = 'Digital Marketing - Email Marketing',
  DIGITAL_MARKETING_DIWY = 'Digital Marketing - Diwy',
  DIGITAL_MARKETING_SOCIAL_MEDIA_MARKETING = 'Digital Marketing - Social Media Marketing',
  DIGITAL_MARKETING_PROMOBOX = 'Digital Marketing - Promobox',
  DIGITAL_MARKETING_DIFY = 'Digital Marketing - DIFY',
  DIGITAL_MARKETING_VISTACREATE_PRO = 'Digital Marketing - VistaCreate Pro',
  PAGE_DEPT_UNINITIALIZED = 'PAGE_DEPT_UNINITIALIZED',
}
export enum TrackingLabel {
  VISTA_WIX_WEBSITE = 'Vista Wix Website',
  VISTA_WEBSITE = 'Vista Website',
  VISTA_SELM = 'Vista SELM',
  VISTA_DOMAIN = 'Vista Standalone Domain',
  VISTA_PRO_ADVANTAGE = 'Vista Pro Advantage',
  VISTA_EMAIL_MARKETING = 'Vista Email Marketing',
  VISTA_SOCIAL_MEDIA_MARKETING = 'Vista Social Media Marketing',
  VISTA_PROMOBOX = 'Vista Promobox',
  VISTA_DIFY = 'Vista DIFY',
  VISTA_VISTACREATE_PRO = 'Vista VistaCreate Pro',
}

export enum TrackingEventDetail {
  VISTA_WIX_PLAN_UPDATE = 'Vista Wix Plan Update Submission',
  VISTA_WEBSITE = 'Vista Website Plan Update Submission',
  VISTA_SELM = 'Vista SELM Plan Update Submission',
  VISTA_DOMAIN = 'Vista Standalone Domain Plan Update Submission',
  VISTA_PRO_ADVANTAGE = 'Vista Pro Advantage Plan Update Submission',
  VISTA_EMAIL_MARKETING = 'Vista Email Marketing Plan Update Submission',
  VISTA_SOCIAL_MEDIA_MARKETING = 'Vista Social Media Marketing Plan Update Submission',
  VISTA_PROMOBOX = 'Vista Promobox Plan Update Submission',
  VISTA_DIFY = 'Vista DIFY Plan Update Submission',
  VISTA_VISTACREATE_PRO = 'Vista VistaCreate Pro Plan Update Submission',
  CONFIRM = 'Confirm',
  CANCEL = 'Cancel',
  TRY_AGAIN = 'Try Again',
}

export enum PageAnalyticsDataKey {
  CATEGORY = 'category',
  DOMAINS = 'domains',
  LOCAL_LISTINGS = 'localListings',
  PRODUCT_SETUP_DOMAINS = 'productSetupDomains',
  PRODUCT_SETUP_WEBSITES = 'productSetupWebsites',
  WEBSITES = 'websites',
  CONNECTED_BUSINESS_CARDS = 'connectedBusinessCards',
  CONNECTED_BUSINESS_CARDS_GALLERY = 'connectedBusinessCardsGallery',
  CONNECTED_BUSINESS_CARDS_STUDIO = 'connectedBusinessCardsStudio',
  TOWER = 'tower',
  WEBSITES_GALLERY = 'websitesGallery',
  // wix
  WIX_WEBSITES = 'wixWebsites',
  WIX_EXISTING_ACCOUNT = 'wixExistingAccount',
  WIX_TERMS_AND_CONDITIONS = 'wixTermsAndConditions',
  WIX_MY_SITES = 'wixMySites',
  WIX_OFAC_ERROR = 'wixOfacError',
  WIX_OTHER_ERROR = 'wixOtherError',
  WIX_SITE_SELECTION = 'wixSiteSelection',
  WIX_INITIAL_BILLING = 'wixInitialBilling',
  WIX_PLANS_WEBSITES = 'wixPlansWebsites',
  WIX_INITIAL_UPGRADE_BILLING = 'wixInitialUpgradeBilling',
  WIX_SECOND_UPGRADE_BILLING = 'wixSecondUpgradeBilling',
  WIX_SECOND_UPGRADE_PLANS_BUSINESS = 'wixSecondUpgradePlansBusiness',
  WIX_PLANS_BUSINESS = 'wixPlansBusiness',
  WIX_PLANS = 'wixPlans',
  WIX_INITIAL_PLANS_WEBSITES = 'wixInitialPlansWebsites',
  WIX_INITIAL_PLANS_BUSINESS = 'wixInitialPlansBusiness',
  WIX_UPGRADE_PLANS_WEBSITES = 'wixUpgradePlansWebsites',
  WIX_UPGRADE_PLANS_BUSINESS = 'wixUpgradePlansBusiness',
  WIX_INITIAL_PLANS = 'wixInitialPlans',
  WIX_CHANGE_PLANS = 'wixChangePlans',
  WIX_UPGRADE_PLANS = 'wixUpgradePlans',
  WIX_CANCEL_STEP_1 = 'wixCancelStep1',
  WIX_CANCEL_STEP_2 = 'wixCancelStep2',
  WEBS_SHUTDOWN = 'websShutdown',
  WIX_DOMAINS = 'wixDomains',
  WIX_DOMAINS_SEARCH = 'wixDomainsSearch',
  WIX_DOMAINS_SEARCH_RESULTS = 'wixDomainsSearchResults',
  WIX_DOMAINS_SITE_SELECTION = 'wixDomainsSiteSelection',
  WIX_DOMAINS_UPSELL = 'wixDomainsUpsell',
  WIX_DOMAINS_CREATION_UPSELL = 'wixDomainsCreationUpsell',
  WIX_DOMAINS_CANCEL = 'wixDomainsCancel',
  WIX_DOMAINS_REGISTRANT_PERIOD = 'wixDomainsRegistrantPeriod',
  WIX_DOMAINS_REGISTRANT_CONTACT = 'wixDomainsRegistrantContact',
  WIX_DOMAINS_REGISTRANT_PRIVACY = 'wixDomainsRegistrantPrivacy',
  WIX_DOMAINS_TERMS_AND_CONDITIONS = 'wixDomainsTermsAndConditions',
  WIX_DOMAINS_ERROR = 'wixDomainsError',
  // diwy
  DIWY_PRODUCT_PAGE = 'diwyProductPage',
  DIWY_CONSOLE_PAGE = 'diwyConsole',
  DIWY_INTAKE_FORM_PAGE = 'diwyIntakeForm',
  DIWY_APPOINTMENT_PAGE = 'diwyAppointment',
  DIWY_BRIEF_PAGE = 'diwyBrief',
  DIWY_CONFIRMATION_PAGE = 'diwyConfirmationPage',
  DIWY_SITE_SELECTOR_PAGE = 'diwyWixWebsiteSelector',

  //vistaprint
  VISTAPRINT_HOME_PAGE = 'vistaprintHomePage',
  // order confirmation
  ORDER_CONFIRMATION = 'orderConfirmation',
  // subman pages
  MY_SUBSCRIPTIONS = 'mySubscriptions',
  MANAGE_SUBSCRIPTION = 'manageSubscription',
  SUBSCRIPTION_HISTORY = 'subscriptionHistory',
  BILLING_HISTORY = 'billingHistory',
  BILLING_DETAILS = 'billingDetails',
  CHANGE_WEBSITE_PLAN = 'changeWebsitePlan',
  CANCEL_SUBSCRIPTION = 'cancelSubscription',
  REACTIVATE_SUBSCRIPTION = 'reactivateSubscription',
  // spud pages
  UPDATE_SUBSCRIPTION_PAYMENT_METHOD = 'updateSubscriptionPaymentMethod',
  UPDATE_SUBSCRRIPTION_BILLING_ADDRESS = 'updateSubscriptionBillingAddress',
  // workspace pages
  WORKSPACE_DASHBOARD = 'workspaceDashboard',
  WEBSITE_PRODUCT_DASHBOARD = 'websiteProductDashboard',
  WEBSITE_SITE_ACTIVITY_DASHBOARD = 'websiteSiteActivityDashboard', // name is redundant but semantically groups site activity with other website dashboards
  WEBSITE_EMAIL_DASHBOARD = 'websiteEmailDashboard',
  WEBSITE_DOMAINS_DASHBOARD = 'websiteDomainsDashboard',
  WORKSPACE_ORDER_REDIRECT = 'workspaceOrderRedirect',
  WORKSPACE_DASHBOARD_REDIRECT = 'workspaceDashboardRedirect',
  LOCAL_LISTINGS_PRODUCT_DASHBOARD = 'localListingsProductDashboard',
  STAND_ALONE_DOMAINS_DASHBOARD = 'standAloneDomainsDashboard',
  PRO_ADVANTAGE_BUSINESS_INFO = 'proAdvantageBusinessInfo',
  PRO_ADVANTAGE_MEMBERSHIP = 'proAdvantageMembership',
  PRO_ADVANTAGE_WHITE_LABEL_SHIPPING = 'proAdvantageWhiteLabelShipping',
  // category product
  COMING_SOON_PAGE = 'comingSoonPage',
}

// Options that can be passed via an object when calling initWithKeys()
export type TrackingOptions = {
  genTrackTribe?: string;
  genTrackVillage?: string;
};

export type TrackingConfiguration = {
  pageName: string;
  pageSection: TrackingPageSection;
  pageStage: TrackingPageStage;
  genTrack?: string;
};

export type PageAnalyticsData = {
  mpvId?: string;
  pageName?: string;
  pageSection?: TrackingPageSection;
  pageSectionID?: TrackingPageSectionID;
  pageStage?: TrackingPageStage;
  pageDept?: TrackingPageDept;
  pageUri?: string;
  productName?: string;
  trackingAssetName?: string;
  productKey?: string;
  variant?: string;
  brand?: string;
  category?: string;
};

export type PageAnalyticsDataRegistry = {
  [key in PageAnalyticsDataKey]?: PageAnalyticsData;
};

const analyticsDataRegistryInput: PageAnalyticsDataRegistry = {
  category: {
    pageName: 'Digital Marketing:Category Page',
    pageSection: TrackingPageSection.CATEGORY_PAGE,
    pageStage: TrackingPageStage.DISCOVER,
    pageUri: '/digital-marketing',
    trackingAssetName: 'Digital Marketing Category Page',
  },
  domains: {
    mpvId: DOMAINS_MPV_ID,
    pageName: 'Domains:Product Page',
    pageSection: TrackingPageSection.PRODUCT_PAGE,
    pageStage: TrackingPageStage.DISCOVER,
    pageUri: '/digital-marketing/domains',
    productName: 'Domains',
    trackingAssetName: 'Domains Product Page',
  },
  localListings: {
    mpvId: LISTINGS_MANAGER_MPV_ID,
    pageName: 'Listings Manager:Product Page',
    pageSection: TrackingPageSection.PRODUCT_PAGE,
    pageStage: TrackingPageStage.DISCOVER,
    pageUri: '/digital-marketing/local-listings',
    productName: 'Listings Manager',
    trackingAssetName: 'Local Listings Product Page',
  },
  productSetupDomains: {
    pageName: 'Product Setup:Domains Flow',
    pageSection: TrackingPageSection.PRODUCT_SETUP,
    pageStage: TrackingPageStage.CONFIGURE,
    pageUri: '/product-setup/package-selection',
    trackingAssetName: 'Product Setup',
  },
  productSetupWebsites: {
    pageName: 'Product Setup:Websites Flow',
    pageSection: TrackingPageSection.PRODUCT_SETUP,
    pageStage: TrackingPageStage.CONFIGURE,
    pageUri: '/product-setup/package-selection',
    trackingAssetName: 'Product Setup',
  },
  websites: {
    mpvId: WEBSITES_MPV_ID,
    pageName: 'Websites (TBYB):Product Page',
    pageSection: TrackingPageSection.PRODUCT_PAGE,
    pageStage: TrackingPageStage.DISCOVER,
    pageUri: '/digital-marketing/websites',
    productName: 'Websites (TBYB)',
    trackingAssetName: 'Websites Product Page',
  },
  connectedBusinessCards: {
    mpvId: CONNECTED_BUSINESS_CARDS_MPV_ID,
    pageName: 'Connected Business Cards:Product Page',
    pageSection: TrackingPageSection.PRODUCT_PAGE,
    pageStage: TrackingPageStage.DISCOVER,
    pageUri: '/digital-marketing/connectedBusinessCards',
    productName: 'connectedBusinessCards',
    trackingAssetName: 'Connected Business Cards Product Page',
  },
  tower: {
    pageName: 'Websites (TBYB):Studio',
    pageSection: TrackingPageSection.STUDIO,
    pageStage: TrackingPageStage.DESIGN,
    productName: 'Websites (TBYB)',
  },
  websitesGallery: {
    pageName: 'Websites:Gallery Page',
    pageSection: TrackingPageSection.DIGITAL_GALLERY,
    pageStage: TrackingPageStage.DISCOVER,
    pageUri: '/digital-marketing/websites/templates',
    trackingAssetName: 'websites Gallery Page',
  },
  connectedBusinessCardsGallery: {
    pageName: 'connectedBusinessCards:Gallery',
    pageSection: TrackingPageSection.GALLERY,
    pageStage: TrackingPageStage.DESIGN,
    pageUri: '/g/business-cards-connect', // TODO: Check with Mert when gallery is setup
    trackingAssetName: 'Connected Business Cards Gallery Page',
  },
  connectedBusinessCardsStudio: {
    pageName: 'connectedBusinessCards:Studio:WebsiteBuilder',
    pageSection: TrackingPageSection.STUDIO_BUILDER,
    pageStage: TrackingPageStage.CONFIGURE,
    pageUri: '/business-cards-connect',
    trackingAssetName: 'Connected Business Cards Studio Page',
    mpvId: CONNECTED_BUSINESS_CARDS_MPV_ID,
    productKey: 'PRD-MGVDPDOBT',
  },
  // wix pages
  wixWebsites: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Websites (TBYB):Product Page',
    pageSection: TrackingPageSection.PRODUCT_PAGE,
    pageStage: TrackingPageStage.DISCOVER,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites',
    productName: 'Websites (TBYB)',
    trackingAssetName: 'Wix Websites Product Page',
  },
  wixExistingAccount: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Vista Wix Existing Account',
    pageSection: TrackingPageSection.LOGIN,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/redirect',
    trackingAssetName: 'Vista Wix Existing Account Page',
  },
  wixTermsAndConditions: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Vista Wix Privacy Policy',
    pageSection: TrackingPageSection.LEGAL,
    pageStage: TrackingPageStage.OTHER,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/terms-and-conditions',
    trackingAssetName: 'Wix Terms And Conditions Page',
  },
  wixSiteSelection: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Vista Wix Plan Upgrade:Manage:Site Selection',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/site-selector',
    trackingAssetName: 'Plan Upgrade  - Site Selection page',
    productName: 'Websites (TBYB)',
  },
  wixDomainsSearchResults: {
    mpvId: DOMAINS_MPV_ID,
    pageName: 'Domain Search Results Page',
    pageSection: TrackingPageSection.SEARCH_RESULTS_PAGE,
    pageStage: TrackingPageStage.DISCOVER,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/domains/search',
    productName: 'Domain Name',
    category: TrackingCategory.DIGITAL_MARKETING,
  },
  wixDomainsSiteSelection: {
    mpvId: DOMAINS_MPV_ID,
    pageName: 'Website Selection Page:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/domains/site-selector',
    productName: 'Domain Name',
  },
  wixDomainsUpsell: {
    mpvId: DOMAINS_MPV_ID,
    pageName: 'Website Plan Upgrade:Manage:Domain Search',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/domains/upgrade',
    productName: 'Domain Name',
  },
  wixDomainsCreationUpsell: {
    mpvId: DOMAINS_MPV_ID,
    pageName: 'Website Creation:Manage:Domain Search',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/domains/upgrade',
    productName: 'Domain Name',
  },
  wixDomainsCancel: {
    mpvId: DOMAINS_MPV_ID,
    pageName: 'Domain Cancellation Selected:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/domains/cancel',
    productName: 'Domain Name',
  },
  wixDomainsRegistrantPeriod: {
    mpvId: DOMAINS_MPV_ID,
    pageName: 'Period:Domain Selection:Configure - Option',
    pageSection: TrackingPageSection.CONFIGURE_OPTIONS,
    pageStage: TrackingPageStage.CONFIGURE,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/domains/registration',
    productName: 'Domain Name',
  },
  wixDomainsRegistrantContact: {
    mpvId: DOMAINS_MPV_ID,
    pageName: 'Contact Info:Domain Selection:Configure - Option',
    pageSection: TrackingPageSection.CONFIGURE_OPTIONS,
    pageStage: TrackingPageStage.CONFIGURE,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/domains/registration',
    productName: 'Domain Name',
  },
  wixDomainsRegistrantPrivacy: {
    mpvId: DOMAINS_MPV_ID,
    pageName: 'Privacy:Domain Selection:Configure - Option',
    pageSection: TrackingPageSection.CONFIGURE_OPTIONS,
    pageStage: TrackingPageStage.CONFIGURE,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/domains/registration',
    productName: 'Domain Name',
  },
  wixDomainsTermsAndConditions: {
    mpvId: DOMAINS_MPV_ID,
    pageName: 'Vista Wix Domains Privacy Policy',
    pageSection: TrackingPageSection.LEGAL,
    pageStage: TrackingPageStage.OTHER,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/domains/terms-and-conditions',
    trackingAssetName: 'Vista Wix Domains Terms And Conditions Page',
  },
  wixDomainsError: {
    mpvId: DOMAINS_MPV_ID,
    pageName: 'Vista Domains Wix Error Page',
    pageSection: TrackingPageSection.INTERNAL_ERROR,
    pageStage: TrackingPageStage.ERROR_PAGE,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/domains/error',
    trackingAssetName: 'Vista Wix Domains Error Page',
  },
  wixMySites: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Wix My Sites',
    pageSection: TrackingPageSection.STUDIO,
    pageStage: TrackingPageStage.DESIGN,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    productName: 'Websites (TBYB)',
  },
  wixOfacError: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Vista Wix OFAC Country Error Page',
    pageSection: TrackingPageSection.INTERNAL_ERROR,
    pageStage: TrackingPageStage.ERROR_PAGE,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/error',
    trackingAssetName: 'Vista Wix OFAC Country Error Page',
  },
  wixOtherError: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Vista Wix Error Page',
    pageSection: TrackingPageSection.INTERNAL_ERROR,
    pageStage: TrackingPageStage.ERROR_PAGE,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/error',
    trackingAssetName: 'Vista Wix Error Page',
  },
  wixInitialBilling: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Vista Wix Plan Billing Cycle:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/initial-billing',
    trackingAssetName: 'Vista Wix Billing Cycle page',
  },
  wixPlansWebsites: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Vista Wix Plan Offers:Configure - Option:Website Plans Tab',
    pageSection: TrackingPageSection.CONFIGURE_OPTION,
    pageSectionID: TrackingPageSectionID.WIX_WEBSITES_PLANS,
    pageStage: TrackingPageStage.CONFIGURE,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/plans',
    trackingAssetName:
      'Vista Wix Plan Offers:Configure - Option:Website Plans Tab',
  },
  wixInitialUpgradeBilling: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Vista Wix Plan Initial Upgrade Billing Cycle:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/initial-billing',
    trackingAssetName: 'Vista Wix Initial Upgrade Billing Cycle page',
  },
  wixSecondUpgradeBilling: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Vista Wix Plan Second Upgrade Billing Cycle:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/upgrade-billing',
    trackingAssetName: 'Vista Wix Second Upgrade Billing Cycle page',
  },
  wixSecondUpgradePlansBusiness: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName:
      'Vista Wix New Plan Second Upgrade:Manage:Business Ecommerce Plans Tab',
    pageSection: TrackingPageSection.MANAGE,
    pageSectionID: TrackingPageSectionID.WIX_BUSINESS_PLANS,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/upgrade-new-plans',
    trackingAssetName:
      'Vista Wix New Plan Second Upgrade:Manage:Business Ecommerce Plans Tab',
  },
  wixPlansBusiness: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName:
      'Vista Wix Plan Offers:Configure - Option:Business Ecommerce Plans Tab',
    pageSection: TrackingPageSection.CONFIGURE_OPTION,
    pageSectionID: TrackingPageSectionID.WIX_BUSINESS_PLANS,
    pageStage: TrackingPageStage.CONFIGURE,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/plans',
    trackingAssetName:
      'Vista Wix Plan Offers:Configure - Option:Business Ecommerce Plans Tab',
  },
  wixPlans: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Vista Wix Plan Offers:Configure - Option',
    pageSection: TrackingPageSection.CONFIGURE_OPTION,
    pageStage: TrackingPageStage.CONFIGURE,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/plans',
    trackingAssetName: 'Vista Wix Plan Offers:Configure - Option',
  },
  wixInitialPlansWebsites: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Vista Wix Plan Initial Upgrade:Manage:Website Plans Tab',
    pageSection: TrackingPageSection.MANAGE,
    pageSectionID: TrackingPageSectionID.WIX_WEBSITES_PLANS,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/initial-plans',
    trackingAssetName: 'Vista Wix Plan Upgrade:Manage:Website Plans Tab',
  },
  wixInitialPlansBusiness: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName:
      'Vista Wix Plan Initial Upgrade:Manage:Business Ecommerce Plans Tab',
    pageSection: TrackingPageSection.MANAGE,
    pageSectionID: TrackingPageSectionID.WIX_BUSINESS_PLANS,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/initial-plans',
    trackingAssetName:
      'Vista Wix Plan Upgrade:Manage:Business Ecommerce Plans Tab',
  },
  wixInitialPlans: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Vista Wix Plan Initial Upgrade:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/initial-plans',
    trackingAssetName: 'Vista Wix Plan Initial Upgrade:Manage',
  },
  wixChangePlans: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Vista Wix Plan Change:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/change-plans',
    trackingAssetName: 'Vista Wix Plan Change:Manage',
    category: '',
  },
  wixUpgradePlansWebsites: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Vista Wix Plan Upgrade:Manage:Website Plans Tab',
    pageSection: TrackingPageSection.MANAGE,
    pageSectionID: TrackingPageSectionID.WIX_WEBSITES_PLANS,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/upgrade-plans',
    trackingAssetName: 'Vista Wix Plan Upgrade:Manage:Website Plans Tab',
  },
  wixUpgradePlansBusiness: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Vista Wix Plan Upgrade:Manage:Business Ecommerce Plans Tab',
    pageSection: TrackingPageSection.MANAGE,
    pageSectionID: TrackingPageSectionID.WIX_BUSINESS_PLANS,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/upgrade-plans',
    trackingAssetName:
      'Vista Wix Plan Upgrade:Manage:Business Ecommerce Plans Tab',
  },
  wixUpgradePlans: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Vista Wix Plan Upgrade:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/upgrade-plans',
    trackingAssetName: 'Vista Wix Plan Upgrade:Manage',
  },
  wixCancelStep1: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Vista Wix Plan Cancellation Request:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/cancel-plans',
    trackingAssetName: 'Vista Wix Plan Cancellation Request:Manage',
  },
  wixCancelStep2: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Vista Wix Plan Cancellation Reason:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/websites/cancel-plans',
    trackingAssetName: 'Vista Wix Plan Cancellation Reason:Manage',
  },
  websShutdown: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'Landing Page:websShutdown',
    pageSection: TrackingPageSection.LANDING_PAGE,
    pageStage: TrackingPageStage.DISCOVER,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WEBSITE,
    pageUri: '/digital-marketing/websites/webs-shutdown',
    trackingAssetName: 'Webs Shutdown Page Request:Manage',
  },
  wixDomains: {
    mpvId: DOMAINS_MPV_ID,
    pageName: 'Domain:Product Page',
    pageSection: TrackingPageSection.PRODUCT_PAGE,
    pageStage: TrackingPageStage.DISCOVER,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/domains',
    productName: 'Domain Name',
    trackingAssetName: 'Wix Domains Product Page',
  },
  wixDomainsSearch: {
    mpvId: DOMAINS_MPV_ID,
    pageName: 'Domain Search:Product Page',
    pageSection: TrackingPageSection.PRODUCT_PAGE,
    pageStage: TrackingPageStage.DISCOVER,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/domains',
    productName: 'Domain Name',
    trackingAssetName: 'Wix Domains Search Product Page',
  },
  // diwy
  diwyConsole: {
    mpvId: DIWY_MPV_ID,
    pageName: 'DIWY:Console Page',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/diwy/console',
    productName: 'Vista x Wix Plus',
    trackingAssetName: 'Vista x Wix Plus Console Page',
  },
  diwyProductPage: {
    mpvId: DIWY_MPV_ID,
    pageName: 'Vista x Wix Plus:Product Page',
    pageSection: TrackingPageSection.PRODUCT_PAGE,
    pageStage: TrackingPageStage.DISCOVER,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/start-diwy',
    productName: 'Vista x Wix Plus',
    trackingAssetName: 'Vista x Wix Plus Product Page',
  },
  diwyIntakeForm: {
    mpvId: DIWY_MPV_ID,
    pageName: 'DIWY:Intake Form Page',
    pageSection: TrackingPageSection.DIGITAL_MARKETING,
    pageStage: TrackingPageStage.CONFIGURE,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/vista-wix-plus/console/intake-form',
    productName: 'Vista x Wix Plus',
    trackingAssetName: 'Vista x Wix Plus Intake Form Page',
  },
  diwyAppointment: {
    mpvId: DIWY_MPV_ID,
    pageName: 'DIWY:Appointment Page',
    pageSection: TrackingPageSection.DIGITAL_MARKETING,
    pageStage: TrackingPageStage.CUSTOMER_CARE,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/vista-wix-plus/console/appointment',
    productName: 'Vista x Wix Plus',
    trackingAssetName: 'Vista x Wix Plus Appointment Page',
  },
  diwyBrief: {
    mpvId: DIWY_MPV_ID,
    pageName: 'DIWY:Brief Page',
    pageSection: TrackingPageSection.DIGITAL_MARKETING,
    pageStage: TrackingPageStage.OTHER,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/vista-wix-plus/console/brief',
    productName: 'Vista x Wix Plus',
    trackingAssetName: 'Vista x Wix Plus Appointment Page',
  },
  diwyConfirmationPage: {
    mpvId: DIWY_MPV_ID,
    pageName: 'DIWY:AppointementConfirmation Page',
    pageSection: TrackingPageSection.DIGITAL_MARKETING,
    pageStage: TrackingPageStage.OTHER,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri:
      '/digital-marketing/vista-wix-plus/console/appointment-confirmation',
    productName: 'Vista x Wix Plus',
    trackingAssetName: 'Vista x Wix Plus Confirmation Page',
  },
  diwyWixWebsiteSelector: {
    mpvId: WIX_WEBSITES_MPV_ID,
    pageName: 'DIWY:Site Selection',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/digital-marketing/vista-wix-plus/site-selector',
    trackingAssetName: 'Vista x Wix Plus Plan Upgrade  - Site Selection page',
    productName: 'Vista x Wix Plus',
  },
  // order confirmation
  orderConfirmation: {
    pageName: 'Checkout:Order:Confirmation',
    pageSection: TrackingPageSection.CONFIRMATION,
    pageStage: TrackingPageStage.CHECKOUT,
    pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
    pageUri: '/od',
  },
  // subscriptions management pages
  mySubscriptions: {
    pageName: 'Subscriptions Settings:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/subscriptions/manage',
    trackingAssetName: 'List of my subscriptions',
  },
  manageSubscription: {
    pageName: 'Subscriptions Details:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/subscriptions/manage',
    trackingAssetName: 'Manage a single subscription',
  },
  subscriptionHistory: {
    pageName: 'Subscriptions History:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/subscriptions/manage/history',
    trackingAssetName: 'Subscription history',
  },
  billingHistory: {
    pageName: 'Subscriptions Billing History:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/subscriptions/manage/billing-history',
    trackingAssetName: 'Billing history',
  },
  billingDetails: {
    pageName: 'Subscriptions Billing Details:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/subscriptions/manage/billing-details',
    trackingAssetName: 'Billing details',
  },
  changeWebsitePlan: {
    pageName: 'Subscriptions Change Plan:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/subscriptions/manage',
    trackingAssetName: 'Change website plan',
  },
  cancelSubscription: {
    pageName: 'Subscriptions Cancel:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/subscriptions/cancel',
    trackingAssetName: 'Cancel subscription',
  },
  reactivateSubscription: {
    pageName: 'Subscriptions Reactivate:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/subscriptions/reactivate',
    trackingAssetName: 'Reactivate subscription',
  },

  // subscriptions update payment pages
  updateSubscriptionPaymentMethod: {
    pageName: 'Subscriptions Update Payment:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/subscriptions/update-payment-method',
    trackingAssetName: 'Update subscription payment method',
  },
  updateSubscriptionBillingAddress: {
    pageName: 'Subscriptions Update Payment:Update Billing Address:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/subscriptions/update-payment-method',
    trackingAssetName: 'Update subscription billing address',
  },

  // workspace dashboard
  workspaceDashboard: {
    pageName: 'Digital Marketing:Workspace Dashboard:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/workspace',
    trackingAssetName: 'Workspace Dashboard',
  },
  // workspace: proAdvantage pages
  proAdvantageBusinessInfo: {
    pageName: 'Digital Marketing:Pro Advantage Business Info:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/workspace/proadvantage-membership/business-info',
    trackingAssetName: 'Pro Advantage Business Info',
  },
  proAdvantageMembership: {
    pageName: 'Digital Marketing:Pro Advantage Membership:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/workspace/proadvantage-membership',
    trackingAssetName: 'Pro Advantage Membership',
  },
  proAdvantageWhiteLabelShipping: {
    pageName: 'Digital Marketing:Pro Advantage White Label Shipping:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/workspace/proadvantage-membership/white-label-shipping',
    trackingAssetName: 'Pro Advantage White Label Shipping',
  },
  // workspace: website dashboards
  websiteProductDashboard: {
    pageName: 'Digital Marketing:Website Dashboard:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/workspace/website/dashboard',
    trackingAssetName: 'Website Product Dashboard',
  },
  websiteSiteActivityDashboard: {
    pageName: 'Digital Marketing:Site Activity Dashboard:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/workspace/website/dashboard',
    trackingAssetName: 'Website Site Activity Product Dashboard',
  },
  websiteEmailDashboard: {
    pageName: 'Digital Marketing:Email Dashboard:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/workspace/website/dashboard',
    trackingAssetName: 'Website Email Product Dashboard',
  },
  websiteDomainsDashboard: {
    pageName: 'Digital Marketing:Domains Dashboard:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/workspace/website/dashboard',
    trackingAssetName: 'Website Domain Product Dashboard',
  },
  // workspace: SELM dashboard
  localListingsProductDashboard: {
    pageName: 'Digital Marketing:SELM Dashboard:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/workspace/listings-manager/dashboard',
    trackingAssetName: 'Local Listings Product Dashboard',
  },
  // workspace: standalone domains dashboard
  standAloneDomainsDashboard: {
    pageName: 'Digital Marketing:Stand Alone Domains Dashboard:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/workspace/domains/dashboard',
    trackingAssetName: 'Stand Alone Domains Dashboard',
  },
  // workspace: redirect pages
  workspaceOrderRedirect: {
    pageName: 'Digital Marketing:Order Redirect:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/workspace/redirect/order',
    trackingAssetName: 'Workspace Order Redirect',
  },
  workspaceDashboardRedirect: {
    pageName: 'Digital Marketing:Dashboard Redirect:Manage',
    pageSection: TrackingPageSection.MANAGE,
    pageStage: TrackingPageStage.MY_ACCOUNT,
    pageUri: '/workspace/redirect',
    trackingAssetName: 'Workspace Dashboard Redirect',
  },
  // Digital Category: Coming Soon
  comingSoonPage: {
    //Update Social Media Management, with future product names to be launched
    pageName: 'Digital Marketing:Social Media Management:Coming Soon',
    pageSection: TrackingPageSection.OTHERS,
    pageStage: TrackingPageStage.DISCOVER,
    pageUri: '/digital-marketing/coming-soon',
    trackingAssetName: 'Digital Category Page',
  },
};

const mapAnalyticsDataRegistry = () => {
  Object.keys(analyticsDataRegistryInput).map((key: PageAnalyticsDataKey) => {
    const page = analyticsDataRegistryInput[key];
    if (!page.mpvId) {
      page.mpvId = '';
    }
    return {
      ...analyticsDataRegistryInput,
    };
  });
  return analyticsDataRegistryInput;
};
export interface AddToCartPriceModel {
  quantity?: number;
  price?: number;
  list_price?: number;
  sales_quantity?: number;
  discount?: number;
  variant?: string;
}

export const changePlanAttributeDict: {
  [id: string]: {
    pageDept: TrackingPageDept;
    label: TrackingLabel;
    eventDetail: TrackingEventDetail;
  };
} = {};
changePlanAttributeDict['WIX_PREMIUM_PLAN'] = {
  pageDept: TrackingPageDept.DIGITAL_MARKETING_WIX,
  label: TrackingLabel.VISTA_WIX_WEBSITE,
  eventDetail: TrackingEventDetail.VISTA_WIX_PLAN_UPDATE,
};
changePlanAttributeDict['VISTACREATE_PRO'] = {
  pageDept: TrackingPageDept.DIGITAL_MARKETING_VISTACREATE_PRO,
  label: TrackingLabel.VISTA_VISTACREATE_PRO,
  eventDetail: TrackingEventDetail.VISTA_VISTACREATE_PRO,
};
changePlanAttributeDict['DIFY'] = {
  pageDept: TrackingPageDept.DIGITAL_MARKETING_DIFY,
  label: TrackingLabel.VISTA_DIFY,
  eventDetail: TrackingEventDetail.VISTA_DIFY,
};
changePlanAttributeDict['PROMOBOX'] = {
  pageDept: TrackingPageDept.DIGITAL_MARKETING_PROMOBOX,
  label: TrackingLabel.VISTA_PROMOBOX,
  eventDetail: TrackingEventDetail.VISTA_PROMOBOX,
};
changePlanAttributeDict['SOCIAL_MEDIA_MARKETING'] = {
  pageDept: TrackingPageDept.DIGITAL_MARKETING_SOCIAL_MEDIA_MARKETING,
  label: TrackingLabel.VISTA_SOCIAL_MEDIA_MARKETING,
  eventDetail: TrackingEventDetail.VISTA_SOCIAL_MEDIA_MARKETING,
};
changePlanAttributeDict['EMAIL_MARKETING'] = {
  pageDept: TrackingPageDept.DIGITAL_MARKETING_EMAIL_MARKETING,
  label: TrackingLabel.VISTA_EMAIL_MARKETING,
  eventDetail: TrackingEventDetail.VISTA_EMAIL_MARKETING,
};
changePlanAttributeDict['PRO_ADVANTAGE'] = {
  pageDept: TrackingPageDept.DIGITAL_MARKETING_PRO_ADVANTAGE,
  label: TrackingLabel.VISTA_PRO_ADVANTAGE,
  eventDetail: TrackingEventDetail.VISTA_PRO_ADVANTAGE,
};
changePlanAttributeDict['STANDALONE_DOMAIN'] = {
  pageDept: TrackingPageDept.DIGITAL_MARKETING_DOMAIN,
  label: TrackingLabel.VISTA_DOMAIN,
  eventDetail: TrackingEventDetail.VISTA_DOMAIN,
};
changePlanAttributeDict['SEARCH_ENGINE_LISTINGS_MANAGER'] = {
  pageDept: TrackingPageDept.DIGITAL_MARKETING_SELM,
  label: TrackingLabel.VISTA_SELM,
  eventDetail: TrackingEventDetail.VISTA_SELM,
};
changePlanAttributeDict['WEBSITE'] = {
  pageDept: TrackingPageDept.DIGITAL_MARKETING_WEBSITE,
  label: TrackingLabel.VISTA_WEBSITE,
  eventDetail: TrackingEventDetail.VISTA_WEBSITE,
};

export const analyticsDataRegistry = mapAnalyticsDataRegistry();
